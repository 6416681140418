<template>
	<div class="page">
		<v-card class="mt-4">
			<v-card-title>
				Menu list
				<v-spacer></v-spacer>
				<v-btn @click="onSave(null)"
						class="ml-3 mb-2 table-custom--btn"
						color="success">
					<v-icon left>add</v-icon>
					{{ $t('btnCreate') }}
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-data-table
						:loading="loadingGetList"
						:loading-text="$t('loadingDataWaiting')"
						:headers="headers"
						:items="list"
						hide-default-footer
						class="elevation-1">
					<template v-slot:item.title="{ item }">
						<router-link
								:to="$localize({ name: `administration.menus.item`, params: { id: item.id } })">
							{{ item.title }}
						</router-link>
					</template>

					<template v-slot:item.actions="{ item }">
						<div style="width: 80px;">
							<v-btn @click="onSave(item)"
									fab
									link
									color="primary"
									x-small>
								<v-icon x-small>
									mdi-pencil
								</v-icon>
							</v-btn>
							<v-btn fab
									class="ml-2"
									color="red"
									@click="onDelete(item)"
									x-small>
								<v-icon
										x-small
										color="white">
									mdi-delete
								</v-icon>
							</v-btn>
						</div>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	import {find, forIn, debounce} from 'lodash';
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	import MenusSave from './MenusSave'

	export default {
		name: 'pages-index',
		data(){
			return {
				currentItem: null,

				headers: [
					{
						text: 'ID',
						value: 'id',
					},
					{
						text: 'Key',
						value: 'key',
					},
					{
						text: 'Menu',
						value: 'title',
					},
					{
						text: 'Description',
						value: 'description',
					},
					{
						text: 'Actions',
						value: 'actions',
						sortable: false
					}
				]
			}
		},

		computed:{
			...mapState({
				list: state => state.administrationsMenus.list,
				listPagination: state => state.administrationsMenus.listPagination,
				listFilter: state => state.administrationsMenus.listFilter,
				loadingGetList: state => state.administrationsMenus.loadingGetList,
				loadingDeleteItem: state => state.administrationsMenus.loadingDeleteItem,
			}),

			...mapGetters({
				listActiveFilter: 'administrationsMenus/listActiveFilter'
			}),
		},

		created(){
			this.getFilterDataFromUrl();
			this.getList();

		},

		methods:{
			...mapMutations({
				SET_FILTER_LIST: 'administrationsMenus/SET_FILTER_LIST'
			}),
			...mapActions({
				GET_LIST: 'administrationsMenus/GET_LIST',
				DELETE_ITEM: 'administrationsMenus/DELETE_ITEM',
			}),


			onSave(item){
				this.$modal.show(MenusSave,{
					currentItem: item,
				}, $MODAL_OPTIONS.default);

				// this.currentItem = item;
				// this.$refs['modalSave'].$emit('event-change-dialog', true);

			},

			onDelete(item){
				this.DELETE_ITEM(item.id);
			},

			getList: debounce(function () {
				this.GET_LIST();
				this.setFilterDataToUrl();
			}, 500),

			getFilterDataFromUrl(){
				let query = this.$route.query;

				forIn(query,(val, key) => {
					this.SET_FILTER_LIST({key: key, val: val});
				})
			},

			setFilterDataToUrl(){
				this.$router.replace({ name: this.$route.name, query: this.listActiveFilter }).catch(() => {});
			},

			onPaginationChangeLimit(limit){
				this.SET_FILTER_LIST({key: 'limit', val: limit});
				this.getList();
			},

			onPaginationChangePage(page){
				this.SET_FILTER_LIST({key: 'page', val: page});
				this.getList();
			}
		}
	};
</script>

<style scoped lang=scss>

</style>
