<template>
	<div class="vmodal vmodal--ease">
		<div class="vmodal__container">
			<div class="vmodal__head">
				<div class="vmodal__title">
					<span>Создать контакт</span>
				</div>
				<div class="vmodal__options">
					<v-btn @click="$emit('close')"
							color="silver"
							class="mr-1">
						{{ $t('close') }}
					</v-btn>
				</div>
			</div>

			<div class="vmodal__main">
				<div class="form">
					<v-form ref="form"
							class="pa-5 pt-0"
							@submit="onSave"
							v-model="valid">
						<v-row>
							<v-col cols="12" sm="6">
								<v-text-field
										label="Name"
										required
										v-model="payload.title"
										:rules="defaultRules"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6">
								<v-text-field
										:class="{'has-disabled': hasEditType}"
										label="Key"
										required
										v-model="payload.key"
										:rules="defaultRules"
								></v-text-field>
							</v-col>

							<v-col cols="12">
								<v-textarea
										label="Description"
										required
										v-model="payload.description"
										:rules="defaultRules"
								></v-textarea>
							</v-col>
						</v-row>


						<v-row class="">
							<v-col cols="12">
								<v-btn type="onSave"
										min-width="150"
										color="success"
										:loading="loadingSaveItem"
										:disabled="loadingSaveItem"
										@click.prevent="onSave"
								>
									{{ $t('save') }}
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {cloneDeep, assign} from 'lodash';
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';
	import validation from '@/mixins/validation';

	export default {
		name: 'menu-save',
		mixin: [validation],
		props: ['currentItem'],
		data(){
			return{
				valid: false,
				payload:{
					country_id: null,
					active: 1,
					key: null,
					type: 'menu',
					title: null,
					description: null,
				}
			}
		},

		computed:{
			...mapState({
				loadingSaveItem: state => state.administrationsMenus.loadingSaveItem,
				// roles: state => state.roles.list
			}),

			hasEditType(){
				return !!this.currentItem;
			},
		},

		created(){
			// this.initCommonData();
			if(this.hasEditType) this.setFormData()
		},

		methods: {
			...mapActions({
				SAVE_ITEM: 'administrationsMenus/SAVE_ITEM',
				// GET_ROLES: 'roles/GET_LIST'
			}),

			onSave () {
				this.$refs.form.validate();

				if (this.valid) {
					let payload = cloneDeep(this.payload);

					this.SAVE_ITEM(assign(payload, {
						id: this.hasEditType ? this.currentItem.id : null,

					})).then((resp) => {
						this.$emit('close');

					})
				}
			},

			setFormData () {
				Object.assign(this.payload, this.currentItem)
			},

			initCommonData () {
				// this.GET_ROLES();
			},
		}
	};
</script>

<style scoped lang=scss>

</style>
