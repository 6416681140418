import { render, staticRenderFns } from "./MenusIndex.vue?vue&type=template&id=433a7f84&scoped=true"
import script from "./MenusIndex.vue?vue&type=script&lang=js"
export * from "./MenusIndex.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "433a7f84",
  null
  
)

export default component.exports